<template>
  <router-view></router-view>
</template>

<script>
import { ApiConnector } from "@/utils/api";

export default {
  data: () => ({}),
  methods: {},
  mounted() {
    setTimeout(async () => {
      if (this.token && !this.$store.state.user?._id) {
        const res = await ApiConnector.getToken(this.token);

        if (res.error) {
          // this.$toast.error(res.message);
          return;
        }

        localStorage.setItem("access_token", res.token);
        this.$nextTick(() => {
          location.reload();
        });
      }
    }, 1300);

    this.$store.commit("initJwt");
    this.$store.dispatch("getStatus");
  },
  watch: {},
  computed: {
    token() {
      return this.$route.query.token;
    },
  },
};
</script>

<style lang="scss" scoped></style>
