<template>
  <div class="flex flex-col">
    <TargetNavbar />
    <MyNavbar />

    <div class="overflow-scroll relative w-full h-full backdrop-blur-sm">
      <img
        class="h-screen absolute z-[0] object-cover w-full"
        src="/img/assets/map-bg.png"
        alt="map-bg"
      />
      <div class="z-1">
        <div class="min-h-screen relative">
          <div
            class="w-full absolute text-center py-4 lg:text-3xl text-lg font-bold text-white lg:p-4 lg:py-5 p-2 bg-black bg-opacity-30"
          >
            Create global writing game...
          </div>
          <div
            class="generation__container pb-5 pt-2 flex justify-end items-end"
          >
            <div
              class="footer px-4 flex flex-col container rounded-[5px] shadow lg:p-4 p-2 lg:text-md text-sm bg-black w-full bg-opacity-30 border border-gray-700 text-white font-semibold"
            >
              <div class="header flex justify-between">
                <div class="flex gap-4">
                  <button
                    @click="type = 'generate'"
                    :class="
                      type === 'generate'
                        ? 'text-primary bg-opacity-5 backdrop-blur-[2px] border-b-2 border-primary'
                        : 'text-white bg-opacity-0'
                    "
                    class="bg-black transition flex items-center gap-2 px-2 py-1"
                  >
                    <i class="fa-solid fa-play"></i>
                    Generate
                  </button>
                </div>

                <div class="flex gap-2 items-center">
                  <div>
                    <el-select class="max-w-[64px]" v-model="form.language">
                      <el-option
                        v-for="lang in Languages()"
                        :key="lang.name"
                        :label="lang.name.toUpperCase()"
                        :value="lang.value"
                      >
                        {{ lang.name.toUpperCase() }}
                      </el-option>
                    </el-select>
                  </div>

                  <button
                    v-if="video?.video"
                    @click="popupVideo = video.video"
                    class="youtube-link__btn bg-primary bg-opacity-50 hover:bg-opacity-60 backdrop-blur-sm py-1 px-3 rounded-[25px] border-2 border-white border-opacity-5 text-gray-300 flex gap-1 items-center transition"
                    target="_blank"
                  >
                    <span>Need help? Watch video</span>
                  </button>
                </div>
              </div>

              <div class="body mt-2 w-full">
                <div class="box">
                  <div
                    class="w-full flex gap-2 items-center relative"
                    v-if="type === 'generate'"
                  >
                    <!--            <div-->
                    <!--              class="file-input z-10 cursor-pointer absolute top-[110px] left-2"-->
                    <!--            >-->
                    <!--              <input-->
                    <!--                accept=".pdf, .pptx"-->
                    <!--                @change="handleFileChange"-->
                    <!--                class="hidden"-->
                    <!--                type="file"-->
                    <!--                id="uploader"-->
                    <!--              />-->
                    <!--              <div class="flex gap-2 items-center">-->
                    <!--                <label-->
                    <!--                  :class="-->
                    <!--                    !file-->
                    <!--                      ? 'border-gray-50 text-gray-50 hover:border-gray-100 hover:text-gray-100'-->
                    <!--                      : ''-->
                    <!--                  "-->
                    <!--                  class="w-[33px] cursor-pointer h-[33px] flex items-center justify-center transition border-2 rounded-[50%]"-->
                    <!--                  for="uploader"-->
                    <!--                >-->
                    <!--                  <i class="fa-solid fa-paperclip"></i>-->
                    <!--                </label>-->
                    <!--                <span v-if="file">-->
                    <!--                  {{ file.name }}-->
                    <!--                </span>-->
                    <!--              </div>-->
                    <!--            </div>-->

                    <div class="flex flex-col gap-2 w-full">
                      <textarea
                        placeholder="Enter theme of the lesson..."
                        v-model="form.theme"
                        class="w-full p-2 placeholder:text-gray-300 placeholder:font-medium bg-transparent backdrop-blur-[2px] border rounded border-gray-600 h-[150px]"
                      ></textarea>

                      <div class="flex items-center justify-between gap-2">
                        <div class="flex gap-4">
                          <div class="flex gap-2 items-center">
                            <label for="">Multiple choice:</label>
                            <el-checkbox
                              :disabled="
                                form.layers.multipleChoice &&
                                checkboxQuantity <= 2
                              "
                              v-model="form.layers.multipleChoice"
                            />
                          </div>

                          <div class="flex gap-2 items-center">
                            <label for="">Keywords:</label>
                            <el-checkbox
                              :disabled="
                                form.layers.keyword && checkboxQuantity <= 2
                              "
                              v-model="form.layers.keyword"
                            />
                          </div>

                          <div class="flex gap-2 items-center">
                            <label for="">Fill in blanks:</label>
                            <el-checkbox
                              :disabled="
                                form.layers.fillInBlank && checkboxQuantity <= 2
                              "
                              v-model="form.layers.fillInBlank"
                            />
                          </div>

                          <div class="flex gap-2 items-center">
                            <label for="">Short answer:</label>
                            <el-checkbox
                              :disabled="
                                form.layers.shortAnswer && checkboxQuantity <= 2
                              "
                              v-model="form.layers.shortAnswer"
                            />
                          </div>
                        </div>

                        <el-dropdown
                          :disabled="form.theme.length < 4 || isLoading"
                          trigger="click"
                        >
                          <button
                            :disabled="form.theme.length < 4 || isLoading"
                            class="h-[50px] disabled:bg-gray-500 disabled:border-gray-500 blend-mode relative w-[50px] text-white flex items-center justify-center text-primary border-2 border-primary bg-primary rounded-[50%] transition"
                          >
                            <Loader v-if="isLoading" />
                            <i v-else class="fa-solid fa-rocket absolute"></i>
                          </button>
                          <template #dropdown>
                            <el-dropdown-menu>
                              <el-dropdown-item
                                @click="generate(s)"
                                :label="s"
                                v-for="s in subjects"
                                :key="s"
                              >
                                {{ s }}
                              </el-dropdown-item>
                            </el-dropdown-menu>
                          </template>
                        </el-dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--      <div class="generation__container flex flex-col justify-end">-->
            <!--        <div-->
            <!--          class="flex flex-col gap-2 bg-black bg-opacity-10 p-5 rounded border border-white border-opacity-10"-->
            <!--        >-->
            <!--          <h1 v-if="isAuth" class="text-2xl font-bold text-white">-->
            <!--            Hello, {{ $store.state.user.name }}-->
            <!--          </h1>-->
            <!--          <div class="flex gap-2">-->
            <!--            <MyInput-->
            <!--              placeholder="Type theme of the lesson..."-->
            <!--              type="text"-->
            <!--              v-model="form.theme"-->
            <!--            />-->
            <!--            <MyButton @click="generate">-->
            <!--              <span v-if="!isLoading">-->
            <!--                {{ isAuth ? "Generate" : "Sign up" }}-->
            <!--              </span>-->
            <!--              <Spinner v-else />-->
            <!--            </MyButton>-->
            <!--          </div>-->

            <!--          <div class="flex justify-between">-->
            <!--            <el-select-->
            <!--              class="max-w-fit min-w-[120px]"-->
            <!--              v-model="form.language"-->
            <!--              name="languages"-->
            <!--            >-->
            <!--              <el-option-->
            <!--                v-for="lang in Languages()"-->
            <!--                :value="lang.value"-->
            <!--                :label="lang.name"-->
            <!--                :key="lang.value"-->
            <!--              >-->
            <!--                {{ lang.name }}-->
            <!--              </el-option>-->
            <!--            </el-select>-->
            <!--          </div>-->
            <!--        </div>-->
            <!--      </div>-->
          </div>

          <YoutubeVideoPopup
            @close="popupVideo = null"
            :popup-video="popupVideo"
          />
        </div>

        <div class="py-5 border-t border-opacity-20 border-white bg-[#14232C]">
          <div class="container flex flex-col gap-2">
            <MyInput placeholder="Search..." type="text" v-model="search" />
            <div
              v-if="filteredGames.length > 0"
              class="grid lg:grid-cols-3 grid-cols-1 gap-3 w-full"
            >
              <PrimaryCard
                @setCreatingGame="() => (isCreatingGame = true)"
                :game="g"
                class="animate-fadeIn w-full"
                :disabled="isCreatingGame"
                v-for="g in filteredGames"
                :key="g._id"
              >
                <template #title>
                  {{ g.name }}
                </template>
                <template #desc>
                  {{ g.description }}
                </template>
              </PrimaryCard>
            </div>
            <div class="w-full text-center text-white text-lg" v-else>
              No data
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyInput from "@/components/UI/Input/input.vue";
import { ApiConnector, socket } from "@/utils/api";
import PrimaryCard from "@/components/UI/Cards/PrimaryCard.vue";
import { Languages, Subjects } from "@/utils/helpers";
import {
  ElOption,
  ElSelect,
  ElDropdown,
  ElDropdownItem,
  ElDropdownMenu,
} from "element-plus";
import YoutubeVideoPopup from "@/components/Shared/YoutubeVideoPopup.vue";
import Loader from "@/components/UI/Loaders/Loader.vue";
import TargetNavbar from "@/components/Shared/TargetNavbar.vue";
import MyNavbar from "@/components/Shared/MyNavbar.vue";

export default {
  name: "HomeView",
  components: {
    MyNavbar,
    TargetNavbar,
    ElDropdown,
    ElDropdownItem,
    ElDropdownMenu,
    Loader,
    YoutubeVideoPopup,
    ElOption,
    ElSelect,
    PrimaryCard,
    MyInput,
  },
  data: () => ({
    subjects: Subjects,
    popupVideo: null,
    form: {
      layers: {
        multipleChoice: true,
        keyword: true,
        fillInBlank: true,
        shortAnswer: true,
      },
      theme: "",
    },
    type: "generate",
    search: "",
    isLoading: false,
    isCreatingGame: false,
    authMode: "sign-in",
  }),
  async mounted() {
    socket.on("generate_questions", async (res) => {
      this.isLoading = false;

      this.$store.commit("addNewGame", res);

      const game = await ApiConnector.playGame(res._id);

      location.href = "/game/" + game.pin;
    });
    socket.on("error", (res) => {
      this.$toast.error(res);
    });
    await this.$store.dispatch("getMyGames");
  },
  methods: {
    Languages() {
      return Languages;
    },
    generate(s) {
      if (!this.isAuth) {
        this.$router.push("/sign-up");
        return;
      }

      if (this.form.theme.length > 2) {
        this.isLoading = true;
        const layers = [];
        const formLayers = this.form.layers;

        Object.entries(formLayers).forEach((value) => {
          const key = value[0];
          const valueElement = value[1];

          if (valueElement) {
            layers.push(key);
          }
        });

        socket.emit("generate_questions", {
          jwtToken: this.$store.state.user.jwtToken,
          theme: this.form.theme,
          subject: s,
          layers,
          language: this.form.language,
        });
      }
    },
  },
  computed: {
    checkboxQuantity() {
      return Object.values(this.form.layers).filter((el) => !!el).length;
    },
    filteredGames() {
      return this.games
        ?.filter((g) => {
          if (this.search) {
            return (
              g.description.includes(this.search) ||
              g.name.includes(this.search)
            );
          }
          return true;
        })
        .reverse();
    },
    isAuth() {
      return this.$store.state.user._id;
    },
    games() {
      return this.$store.state.games;
    },
  },
};
</script>

<style scoped>
.generation__container {
  height: calc(100vh - 100px);
}
</style>
