import axios from "axios";
import { io } from "socket.io-client";

const isDev = process.env.NODE_ENV === "development";
export const ENV_MODE = "";

export const staticUrl = isDev
  ? ENV_MODE === "beta"
    ? "http://localhost:3001"
    : "http://localhost:3001"
  : ENV_MODE === "beta"
  ? "https://global-api.wordrush.org"
  : "https://global-api.wordrush.org";

class Api {
  constructor() {
    this.storageBaseUrl = staticUrl + "/";
  }

  async getStorage(path, params, opts = {}) {
    try {
      const headers = {};

      const jwt = localStorage.getItem("access_token");

      if (!opts.noJwt) {
        headers.authorization = `Bearer ${jwt}`;
      }

      const url = new URL(path, this.storageBaseUrl);

      const res = await axios({
        method: "get",
        url: url.toString(),
        params,
        headers,
      });

      return res.data;
    } catch (e) {
      return {
        error: true,
        message: e.response.data.message,
      };
    }
  }

  async postStorage(path, data, params) {
    const headers = {};
    const jwt = localStorage.getItem("access_token");

    headers.authorization = `Bearer ${jwt}`;

    const url = new URL(path, this.storageBaseUrl);

    const res = await axios({
      method: "post",
      url: url.toString(),
      headers,
      params,
      data,
    });

    return res["data"];
  }

  async deleteStorage(path, data, params) {
    const headers = {};
    const jwt = localStorage.getItem("access_token");

    headers.authorization = `Bearer ${jwt}`;

    const url = new URL(path, this.storageBaseUrl);

    const res = await axios({
      method: "delete",
      url: url.toString(),
      headers,
      params,
      data,
    });

    return res["data"];
  }

  async patchStorage(path, data) {
    const headers = {};
    const jwt = localStorage.getItem("access_token");

    headers.authorization = `Bearer ${jwt}`;

    const url = new URL(path, this.storageBaseUrl);

    const res = await axios({
      method: "patch",
      url: url.toString(),
      headers,
      data,
    });

    return res["data"];
  }

  async login(data) {
    try {
      const res = await this.postStorage("/auth/login/", data);

      localStorage.setItem("access_token", res.access_token);

      return res;
    } catch (e) {
      return { error: true, message: e.response.data.message };
    }
  }

  async register(data) {
    try {
      const res = await this.postStorage("/auth/register/", data);

      localStorage.setItem("access_token", res.access_token);

      return res;
    } catch (e) {
      return { error: true, message: e.response.data.message };
    }
  }

  async getStatus() {
    try {
      return await this.getStorage("/auth/status");
    } catch (e) {
      return {};
    }
  }

  async getMyGames() {
    return await this.getStorage("/game");
  }

  async playGame(id) {
    return await this.postStorage("/game/" + id);
  }

  async getGame(pin) {
    return await this.getStorage("/game/" + pin);
  }

  async getGameData(id) {
    return await this.getStorage(`/game/one/${id}`);
  }

  async getToken(token) {
    return await this.getStorage("/auth/token/" + token, {}, { noJwt: true });
  }

  async generateExplanation(prompt) {
    try {
      return await this.postStorage("/ai/explanation", { prompt });
    } catch (e) {
      return {
        error: true,
        message: e.response.data.message,
      };
    }
  }

  async saveTags(id, tags) {
    return await this.patchStorage(`/game/${id}`, { tags: tags });
  }

  async setTag(id, idx, tag) {
    return await this.patchStorage(`/game/tag/${id}`, { tag, idx });
  }

  async setTagsToCountries(gameId, tags) {
    return await this.postStorage(`/ai/tags`, { gameId, tags });
  }
}

export const ApiConnector = new Api();

export const socket = io(staticUrl);
